.privacy-container{
    height: auto;
    background-color: #fff;
}
.privacy-container .socialmedialinkwrapper{
    padding: 50px 0 !important;
}
.privacy-container .pwrapper{
    width: 1215px;
    margin: 0 auto 60px auto;
}
.privacy-container .privacyheader{
    background: #000;
}

.privacy-container .pwrapper p{
    font-size: 15px;   
    text-align: justify;
    text-transform: capitalize;
   
}
.policy-sec{
    margin-top: 60px;
}
.policy-sec h1{
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
}
.policy-sec h2{
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
}

