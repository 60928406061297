body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
@media (hover: none) and (pointer: coarse) {    
  .home-meal-plans-wrapper::-webkit-scrollbar {
      display: none;
      width: 0; 
      background: transparent;
  }
}
a{
  text-decoration: none;
  color:#fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbarlink {
  color: #fff;
  text-decoration: none;
}

.top-arrow {
  position: absolute;
  bottom: 30px;
  right: -50px;
}
.props1{
position: absolute;
bottom: 150px;
right:20px;
width:100px;
}
.props1 img{
  width:100%;
}
.props2{
  position: absolute;
  top: -40px;
  right:-115px;
}
.props3{
  position: absolute;
  top: 20px;
  right:-70px; 
}
@media(max-width:1350px) {
  .props2{
    position: absolute;
    top: -40px;
    right:-95px;
  }
  .props3{
    position: absolute;
    top: 20px;
    right:20px; 
  }

}

@media(max-width:1200px) {
  .top-arrow {   
    bottom: 10px;
    right: 60px;
  }
  .props1{    
    bottom: 150px;
    right:20px;
    width:100px;
    }    
    .props2{    
      top: -40px;
      right:-70px;
    }
    .props3{     
      top: 20px;
      right:-20px; 
    }
}

@media (max-width: 992px) {
  .top-arrow {   
    bottom: 10px;
    right: 20px;
    display: none;
  }
  .props1{    
    bottom: 150px;
    right:20px;
    width:100px;
    }    
    .props2{    
      top: -40px;
      right:-10px;
    }
    .props3{     
      top: 20px;
      right:-10px; 
    }
}

@media (max-width: 768px) {
  .top-arrow {
    display: none;
  }
}
.navbtn{
  display: inline-block;
  text-decoration: none;
  width: 140px;
  height:45px;
  line-height:45px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
 font-size: 18px;
  color: #fff;
  outline: none;
  cursor: pointer;
}