.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tel-input .form-control{

  border-radius: 50px !important;
    border: none !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    width: 100% !important;
    padding: 14px !important;
    color: #fff !important;
    outline: none !important;
    height: 50px !important;
    padding-left: 48px !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background: none !important;
}
.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background: none !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown {
  left: 5px !important;
  background: none !important;
  border: 0 !important;
}

input:-internal-autofill-selected{
  background-color: none !important;
}